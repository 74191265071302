.error {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}
.heading {
  font-weight: bold;
  font-size: large;
}
