@tailwind base;
@tailwind components;
@tailwind utilities;

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}
